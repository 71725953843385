import * as React from "react";
import PetePete from "../images/pete-pete.jpg";
import PetePeteTomTom from "../images/pete-pete-tom-tom.jpg";
import styled from "@emotion/styled";

const Main = styled.main`
  max-width: 100vw;
  background-color: red;
`;

const Img = styled.img`
max-width: 100%;
`

const IndexPage = () => {
  return (
    <Main>
      <h1>Tom‘s Frot Farm LLC</h1>
      <Img src={PetePeteTomTom}></Img>
    </Main>
  );
};

export default IndexPage;
export const Head = () => <title>Home Page</title>;
